import "./App.css";
import * as THREE from "three";
import { Suspense, useRef, useState } from "react";
import { Canvas, createPortal, useFrame, useThree } from "@react-three/fiber";
import {
  useGLTF,
  ScrollControls,
  Scroll,
  useScroll,
  Preload,
  Image,
  MeshTransmissionMaterial,
  useFBO,
  useProgress,
  Html,
} from "@react-three/drei";
import { useMediaQuery } from "react-responsive";

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <div style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
        {Math.floor(progress)}% loaded
      </div>
    </Html>
  );
}

export default function App() {
  return (
    <>
      <div id="slit-container"></div>

      <div class="container">
        <div class="effect"></div>
        <p class="small-text"><i>Are you lost?</i> &nbsp; Dream Concepts Inc. helps founders and companies materialize their dreams fast.</p>


        <p>It’s my tenth birthday. My mom is preparing birthday decorations for a pizza party. While setting up balloons, she pulls out scissors out of the kitchen cupboard.  “stop!” I yell. “Are you going to pop all the balloons?” After a quick glance at me, my mom opens the scissors, grabs one handle and starts to curl the balloon ribbons with the edge of the blade. What is happening? I thought scissors were only used for cutting things. </p>

        <p>We are not meant to be beholden to our tools, rather we should be able to wield our tools. What makes a tool? A craft tool is one that has clear utility, but also opens up possibilities for creative adaptation. Much like how it’s clear what the purpose of scissors are, that same tool can be creatively adapted to be used as a box cutter, a ribbon curler, or for the daring, a fidget spinner.</p>

        <p>In any case, it should be very clear what a specific tool does. At the same time, that tool should be designed in such a way that it opens up possibilities depending on who is wielding it.</p>

        <h4>Dream materialization</h4>

        <p>I am the best at helping founder, companies, and products navigate the ambiguity of pre-product and guide them to a meaningfully viable product. Extracting meaningful insights from a pool of ambiguous prompts in order to design a craft product is what I thrive at doing. </p>

        <p>Not only does the first product need to work, it needs to scale. I am the type of designer who will consider everything surrounding a product: the roadmap, the vision, and the people to develop a unique framework for your product. No cutting corners, no more default design systems, and no more boring interfaces. </p>

        <h4>What can I do for you?</h4>

        <p><i class="bold">I will be your founding product designer for the first 6-12 months.</i> I am the unique combination of having the efficiency of a principal, flexibility of a new grad, and the experience of being a founding product designer.</p>

        <p><i class="bold">Run the discovery process of building your first product.</i> Before I propose anything, I zoom out to understand your vision, the market, and the people you are building for.</p>

        <p><i class="bold">Design innovative tools.</i> As a craftperson, I care deeply about any tool that I design. I want all my interfaces to stand on their own as art and find new design paradigms.</p>

        <p><i class="bold">Formalize your product process.</i> I will cultivate a culture of craft and build your design/engineering roadmap.</p>

        <p><i class="bold">Dream with you full time.</i> I am not looking for part-time contract gigs. Your dream is mine until we materialize it. I’m here to show up, execute, and deliver.</p>

        <p><i class="bold">Help recruit, hire, and onboard my eventual replacement.</i> With the ambiguous stuff out of the way, you can focus on hiring for a specific role.</p>



      </div>
    </>
  );
}










